<template>
  <div class="homepage-container">
    <!--====== Scroll To Top Area Start ======-->
    <!--    <div-->
    <!--      v-if="!isMobile"-->
    <!--      id="scrollUp"-->
    <!--      title="Scroll To Top"-->
    <!--    >-->
    <!--      &lt;!&ndash;      <i class="fas fa-arrow-up"></i>&ndash;&gt;-->
    <!--      <svg-->
    <!--        class="svg-inline&#45;&#45;fa fa-arrow-up fa-w-14"-->
    <!--        aria-hidden="true"-->
    <!--        focusable="false"-->
    <!--        data-prefix="fas"-->
    <!--        data-icon="arrow-up"-->
    <!--        role="img"-->
    <!--        xmlns="http://www.w3.org/2000/svg"-->
    <!--        viewBox="0 0 448 512"-->
    <!--        data-fa-i2svg=""-->
    <!--      >-->
    <!--        <path-->
    <!--          fill="currentColor"-->
    <!--          d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"-->
    <!--        >-->
    <!--        </path>-->
    <!--      </svg>-->
    <!--    </div>-->
    <!--====== Scroll To Top Area End ======-->

    <div class="main-scroll">
      <div class="main">
        <!-- ***** Header Start ***** -->
        <section
          class="navbar navbar-sticky navbar-expand-lg"
          :class="{'active': isActive}"
        >
          <div class="container justify-content-between">
            <button
              class="navbar-toggler nav-item  d-lg-none"
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
              @click="isActive = !isActive"
            >
              <span
                class="navbar-toggler-icon"
                :class="{'active': isActive}"
              ></span>
            </button>
            <a
              class="navbar-brand"
              href="#"
            >
              <img
                class="navbar-brand-regular"
                src="./home-assets/img/logo/logo-white.svg"
                alt="brand-logo"
              >
              <img
                class="navbar-brand-sticky"
                src="./home-assets/img/logo/logo-white.svg"
                alt="sticky brand-logo"
              >
            </a>
            <a
              class="nav-item d-lg-none"
              href="#"
              @click.prevent="showModalAuth = true"
            >
              <img
                class="navbar-user-icon"
                src="./home-assets/img/icon/login.svg"
                alt=""
              >
            </a>
            <div class="navbar-inner">
              <!--  Mobile Menu Toggler -->
              <button
                class="navbar-toggler d-lg-none"
                type="button"
                data-toggle="navbarToggler"
                aria-label="Toggle navigation"
                @click="isActive = !isActive"
              >
                <span
                  class="navbar-toggler-icon"
                  :class="{'active': isActive}"
                ></span>
              </button>
              <div class="navbar-inner__logo">
                <img
                  src="./home-assets/img/logo/logo-white.svg"
                  alt=""
                >
              </div>
              <div class="navbar-inner-navigation">
                <div
                  class="navbar-inner-navigation__item"
                  :class="{'navbar-inner-navigation__item_active': $route.name === 'limitedtimeoffer'}"
                  @click="gotToRoute('limitedtimeoffer')"
                >
                  Websites
                </div>
                <div
                  class="navbar-inner-navigation__item"
                  :class="{'navbar-inner-navigation__item_active': $route.name === 'mobilePage'}"
                  @click="gotToRoute('mobilePage')"
                >
                  Mobile app
                </div>
                <div
                  class="navbar-inner-navigation__item"
                  :class="{'navbar-inner-navigation__item_active': $route.name === 'tvPage'}"
                  @click="gotToRoute('tvPage')"
                >
                  TV app
                </div>
              </div>
              <nav>
                <ul
                  id="navbar-nav"
                  class="navbar-nav text-center"
                >
                  <a
                    class="btn prolend-primary"
                    href="#"
                    @click.prevent="showModalAuth = true"
                  >
                    Client Login
                  </a>
                  <a
                    class="btn btn-second prolend-primary btn-website-signup"
                    href="#"
                    @click.prevent="handlerClickSignUp"
                  >
                    Sign Up
                  </a>
                </ul>
              </nav>
            </div>
          </div>
        </section>
        <!-- ***** Header End ***** -->
        <!-- ***** Welcome Area Start ***** -->
        <section
          id="home"
          class="section welcome-area h-100 overflow-hidden"
        >
          <div class="container h-100">
            <div
              id="pop1"
              class="popbox"
            ></div>
            <div class="row justify-content-center align-items-center h-100 mt-4">
              <!-- Welcome Intro Start -->
              <div class="welcome-area__continent">
                <img
                  src="./assets/images/africa.png"
                  alt="africa"
                >
              </div>
              <div class="left-top-cloud"></div>
              <div class="right-top-cloud"></div>
              <div class="right-top-cloud-small"></div>
              <div class="col-12 col-md-10 col-lg-12">
                <div class="welcome-intro text-center">
                  <h1 class="text-black">
                    The Next Big Thing
                    <div>
                      For Churches
                      <span class="block">
                        in
                        <span class="color">Africa</span>
                      </span>
                    </div>
                  </h1>
                  <div class="col-12 col-md-8 welcome-intro__text">
                    <p class="mt-4 mx-3 sub-title">
                      The world is changing. What worked yesterday is not working today.
                      <br>
                      Introducing the next-gen websites that generate unprecedented results.
                    </p>
                  </div>
                  <div class="impact-amo-form">
                    <transition
                      name="fade-block"
                      mode="out-in"
                    >
                      <div v-if="!isSendEmail">
                        <input
                          v-model="email"
                          type="text"
                          class="impact-amo-form__input"
                          :class="{'impact-amo-form__input_error': validator.errors.has('email')}"
                          placeholder="Enter your email to get a free demo"
                          @keydown.enter="sendFormGetFreeDemo"
                        >
                        <button
                          class="impact-amo-form__btn btn-website-getfreedemo"
                          @click="sendFormGetFreeDemo"
                        >
                          Get a Free Demo
                        </button>
                        <transition name="fade-msg">
                          <span
                            v-show="validator.errors.has('email')"
                            class="help is-danger"
                          >
                            {{ validator.errors.first('email') }}
                          </span>
                        </transition>
                      </div>
                      <div
                        v-else
                        class="impact-amo-form__thank"
                      >
                        Thank you! Please
                        <a
                          href="https://calendly.com/d/cmv-mf2-9zh/demo-of-impactfactors-vip-50-discount?month=2022-01"
                          target="_blank"
                          @click="isSendEmail = false"
                        >
                          pick a date here
                        </a>
                      </div>
                    </transition>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="left-top-arrow"></div>
                    <div class="right-top-arrow"></div>
                  </div>
                </div>

                <!-- ***** Promo Video Area Start ***** -->
                <div class="promo-video-area">
                  <div class="container h-100">
                    <div class="row h-100 justify-content-center pt-4 pt-md-0">
                      <div class="col-12 col-md-10 col-lg-12 h-100">
                        <vimeoPlayer
                          :vimeoUrl="
                            isMobile
                              ? 'https://player.vimeo.com/video/536675752'
                              : 'https://vimeo.com/533534418/ecd7966ae0'
                          "
                          :playVideoId.sync="playVideoId"
                          :videoId="1"
                          :showBlock="true"
                          :isMobile="isMobile"
                          :coverUrl="isMobile ? 'cover1_mobile.jpg' : 'player-1.png'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ***** Promo Video Area End ***** -->
              </div>
            </div>
          </div>
        </section>
        <!-- ***** Welcome Area End ***** -->

        <!-- ***** Preview Area Start ***** -->
        <section class="preview-area pb_100">
          <div class="container">
            <div class="d-flex justify-content-between">
              <div class="st1 star-images">
                <img
                  id="star-animation-1"
                  src="./home-assets/img/icon/star.png"
                  alt=""
                >
              </div>
              <div class="st2 star-images">
                <img
                  id="star-animation-2"
                  src="./home-assets/img/icon/star.png"
                  alt=""
                >
              </div>
              <div class="st3 star-images">
                <img
                  id="star-animation-3"
                  src="./home-assets/img/icon/star.png"
                  alt=""
                >
              </div>
              <div class="st4 star-images">
                <img
                  id="star-animation-4"
                  src="./home-assets/img/icon/star.png"
                  alt=""
                >
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-9">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                  <img
                    class="heart-icon"
                    src="./home-assets/img/icon/heart.png"
                    alt=""
                  >
                  <h2 class="text-white">
                    Grow Donations<br> Like Never Before
                  </h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center align-items-center">
              <div class="col-11 col-sm-12 col-lg-8">
                <div class="reviews owl-carousel">
                  <div class="single-preview text-center">
                    <p class="grey-text">
                      See what makes our Giving platform unique besides the fact that it’s completely free with the lowest transaction fees on the market
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ***** Promo Video Area Start ***** -->
          <div class="promo-video-area promo-video-area_second">
            <div class="container h-100">
              <div class="row h-100 justify-content-center pt-4 pt-md-0">
                <div class="col-12 col-md-10 col-lg-12 h-100">
                  <vimeoPlayer
                    :vimeoUrl="'https://vimeo.com/533556394/7ad0fb46cf'"
                    :playVideoId.sync="playVideoId"
                    :videoId="2"
                    :isMobile="isMobile"
                    :coverUrl="isMobile ? 'cover2_mobile.jpg' : 'cover2.png'"
                    :showBlock="showBlock"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- ***** Promo Video Area End ***** -->
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-9">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                  <div
                    id="animCoin"
                    class="avatar mt-5 mx-auto"
                  >
                    <img
                      src="./home-assets/img/icon/coin.png"
                      alt=""
                    >
                  </div>
                  <h2 class="text-white">
                    Simple. Easy. Ready.
                  </h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center align-items-center">
              <div class="col-11 col-sm-12 col-lg-7">
                <div class="reviews owl-carousel">
                  <div class="single-preview text-center">
                    <p class="grey-text">
                      No coding or design skills necessary. Ready-made pages, modules & blocks, designed by the experts specifically for the church and ministry
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ***** Promo Video Area Start ***** -->
          <div class="promo-video-area promo-video-area_third promo-video-area_second">
            <div class="container h-100">
              <div class="row h-100 justify-content-center pt-4 pt-md-0">
                <div class="col-12 col-md-10 col-lg-12 h-100">
                  <vimeoPlayer
                    :vimeoUrl="'https://vimeo.com/533237336/e011861c03'"
                    :playVideoId.sync="playVideoId"
                    :videoId="3"
                    :isMobile="isMobile"
                    :showBlock="showBlock"
                    :coverUrl="isMobile ? 'cover3_mobile.jpg' : 'cover3.jpg'"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- ***** Promo Video Area End ***** -->
        </section>
        <!-- ***** Preview Area End ***** -->

        <contentBlock
          v-if="showBlock"
          :isMobile="isMobile"
          :showModalForm.sync="showModalForm"
        />
      </div>
    </div>
    <transition
      name="fade-modal"
      appear
    >
      <modals-auth
        v-if="showModalAuth"
        :showModalAuth.sync="showModalAuth"
      >
      </modals-auth>
    </transition>
    <transition
      name="fade-modal"
      appear
    >
      <modals-form-seven
        v-show="showModalForm"
        :showModalForm.sync="showModalForm"
      >
      </modals-form-seven>
    </transition>
    <transition
      :name="!showStraightOffModalSignUp && 'animate-unfolding__get-started'"
      appear
      mode="in-out"
    >
      <modals-get-started
        v-if="showModalGetStarted"
        :showModalAttendance.sync="showModalAttendance"
        :showStraightOffModalSignUp="showStraightOffModalSignUp"
      >
      </modals-get-started>
      <modals-attendance
        v-if="showModalAttendance"
        :showModalAttendance.sync="showModalAttendance"
        :showStraightOffModalSignUp="showStraightOffModalSignUp"
      >
      </modals-attendance>
      <transition
        name="fade-block-wait"
        appear
        mode="out-in"
      >
        <modals-privacy-policy
          v-if="showModalPrivacyPolicy"
        >
        </modals-privacy-policy>
      </transition>
    </transition>
    <!--    <div id="amoBtn"></div>-->
  </div>
</template>

<script>
import MobileDetect from 'mobile-detect';
import { Validator } from 'vee-validate';
import amoCrmApi from '@/api/amoCrmApi';
import modalsGetStarted from '@/components/modals/modalsGetStarted';
import { MODE_MOBILE, MODE_PC } from './constants/viewMode';

const modalsAuth = () => import('@/components/modals/modalsAuth');
// const modalsForm = () => import('@/components/modals/modalsForm');
const modalsFormSeven = () => import('@/components/modals/modalsFormSeven'); // new modalsForm
const modalsAttendance = () => import('@/components/modals/modalsAttendance');
const modalsPrivacyPolicy = () => import('@/components/modals/modalsPrivacyPolicy');
const vimeoPlayer = () => import('@/components/vimeoPlayer');
const contentBlock = () => import('@/components/content');

export default {
  name: 'HomePage',
  components: {
    modalsAuth,
    modalsGetStarted,
    modalsAttendance,
    // modalsForm,
    modalsFormSeven,
    vimeoPlayer,
    modalsPrivacyPolicy,
    contentBlock,
  },
  props: ['windowLoaded'],
  data() {
    return {
      isActive: false,
      showBlock: false,
      playVideoId: -1,
      showModalForm: false,
      showModalAuth: false,
      showModalAttendance: false,
      viewModeDef: MODE_PC,
      validator: {},
      isSendEmail: false,
      email: '',
    };
  },
  computed: {
    showModalPrivacyPolicy: {
      get() {
        return this.$store.getters.showModalPrivacyPolicy;
      },
      set(data) {
        this.$store.commit('setShowModalPrivacyPolicy', data);
      },
    },
    showStraightOffModalSignUp: {
      get() {
        return this.$store.getters.showStraightOffModalSignUp;
      },
      set(data) {
        this.$store.commit('setShowStraightOffModalSignUp', data);
      },
    },
    // currentYear () {
    //   const today = new Date()
    //   const year = today.getFullYear()
    //   return year
    // },
    viewMode: {
      get() {
        return this.viewModeDef;
      },
      set(data) {
        this.viewModeDef = data;
      },
    },
    isMobile() {
      return this.viewMode === MODE_MOBILE;
    },
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
  },
  beforeMount() {
    const md = new MobileDetect(window.navigator.userAgent);
    const mobile = md.mobile();
    const tablet = md.tablet();
    this.viewMode = mobile !== null && tablet === null ? MODE_MOBILE : MODE_PC;
    document.body.classList.add('homepage');

    const { query } = this.$route;
    if (Object.keys(query).length) {
      const queryUrlParam = 'prayerwall';
      this.showStraightOffModalSignUp = Object.prototype.hasOwnProperty.call(query, queryUrlParam);
      if (this.showStraightOffModalSignUp) {
        this.showModalGetStarted = true;
        document.body.style.overflowY = 'hidden';
      }
    }

    this.validator = Validator.create();
    const dict = {
      en: {
        custom: {
          email: {
            required: 'The Email is required.',
            email: 'The Email field must be a valid email.',
          },
        },
      },
    };
    this.validator.localize(dict);
    this.validator.attach({ name: 'email', rules: { required: true, email: true } });
    if (!this.windowLoaded) {
      window.addEventListener('load', () => {
        this.showBlock = true;
        // const [preloaderWapper] = document.getElementsByClassName('preloader-wapper')
        // if (preloaderWapper) {
        //   preloaderWapper.classList.add('loaded')
        //   setTimeout(() => {
        //     const [preloaderMain] = document.getElementsByClassName('preloader-main')
        //     preloaderMain.remove()
        //   }, 1000)
        // }
      });
    } else {
      this.showBlock = true;
    }
  },
  beforeDestroy() {
    // window.scroller.destroy()
    document.body.classList.remove('homepage');
    // const amoBtn = document.getElementsByClassName('amo-button-holder');
    // if (amoBtn && amoBtn.length) {
    //   amoBtn[0].remove();
    // }
  },
  mounted() {
    // if (window.scroller) {
    //   this.$nextTick(() => {
    //     window.scroller.init()
    //   })
    // }
    console.log('mounted 1');
    this.$nextTick(() => {
      // setTimeout(() => {
      // this.showBlock = true
      // Custom scroll plugins
      // const scrollTrigger = require('./home-assets/js/ScrollTrigger.min.js')
      // const { ScrollTrigger } = scrollTrigger.window
      // window.ScrollTrigger = ScrollTrigger
      // window.gsap = require('./home-assets/js/gsap.min.js')
      // const { LocomotiveScroll } = require('./home-assets/js/locomotive-scroll.min.js')
      // window.LocomotiveScroll = LocomotiveScroll
      // window.plugins = require('./home-assets/js/plugins/plugins.min.js')
      // // Active js
      // window.active = require('./home-assets/js/active.js')
      const [preloaderWapper] = document.getElementsByClassName('preloader-wapper');
      preloaderWapper.classList.add('loaded');
      // window.slider = require('./home-assets/js/slider.js')
      // // Parallax animation
      // window.scrollParallax = require('./home-assets/js/scroll-parallax.js')
      // // window.lottieAnimations = require('./home-assets/js/lottie-animations.js')
      // window.resizing = require('./home-assets/js/resizing.js')0);
      setTimeout(() => {
        const [preloaderMain] = document.getElementsByClassName('preloader-main');
        preloaderMain.remove();
        // const script = document.createElement('script')
        // script.innerHTML = ''
        // /* eslint-disable */
        // const innerJsCode =
        //   document.createTextNode(`(function(a,m,o,c,r,m){a[m]={id:"39501",hash:"d57954a332ead35c950ee7a39226c22ed791ee2033189ed77dd8f346ff1ee50a",locale:"en",inline:false,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.com/js/button.js?1616513092';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));`)
        // /* eslint-enable */
        // script.appendChild(innerJsCode)
        // document.getElementById('amoBtn').appendChild(script)
        // Slider js
      }, 500);
      // }, 100);
      // setTimeout(() => {
      //   const scrollTrigger = require('./home-assets/js/ScrollTrigger.min.js')
      //   const { ScrollTrigger } = scrollTrigger.window
      //   window.ScrollTrigger = ScrollTrigger
      //   window.gsap = require('./home-assets/js/gsap.min.js')
      //   const { LocomotiveScroll } = require('./home-assets/js/locomotive-scroll.min.js')
      //   window.LocomotiveScroll = LocomotiveScroll
      //   window.plugins = require('./home-assets/js/plugins/plugins.min.js')
      //   // Active js
      //   window.active = require('./home-assets/js/active.js')
      //   window.slider = require('./home-assets/js/slider.js')
      //   // Parallax animation
      //   window.scrollParallax = require('./home-assets/js/scroll-parallax.js')
      //   // window.lottieAnimations = require('./home-assets/js/lottie-animations.js')
      //   window.resizing = require('./home-assets/js/resizing.js')
      //   const script = document.createElement('script')
      //   script.innerHTML = ''
      //   /* eslint-disable */
      //   const innerJsCode =
      //     document.createTextNode(`(function(a,m,o,c,r,m){a[m]={id:"39501",hash:"d57954a332ead35c950ee7a39226c22ed791ee2033189ed77dd8f346ff1ee50a",locale:"en",inline:false,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.com/js/button.js?1616513092';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));`)
      //   /* eslint-enable */
      //   script.appendChild(innerJsCode)
      //   document.getElementById('amoBtn').appendChild(script)
      // }, 500)
    });
    // window.fontAwesome = require('./home-assets/js/plugins/fontAwesome.js')
  },
  methods: {
    handlerClickSignUp() {
      this.showModalGetStarted = true;
      window.Intercom('trackEvent', 'Sign Up on Website');
    },
    sendFormGetFreeDemo() {
      this.validator.validateAll({
        email: this.email,
      }).then((result) => {
        if (result) {
          this.isSendEmail = true;
          const data = {
            fields: [
              {
                name: 'Email',
                value: this.email,
                position: 1,
              },
            ],
          };
          amoCrmApi.sendLeadsToAmoSrm({ data, id: 6 }).then();
        }
      });
    },
    gotToRoute(name) {
      this.$router.push({ name });
    },
    // initScripts () {
    //   if (window.scroller) {
    //     this.$nextTick(() => {
    //       window.scroller.init()
    //     })
    //   }
    //   const script = document.createElement('script')
    //   script.innerHTML = ''
    //   /* eslint-disable */
    //   const innerJsCode =
    //     document.createTextNode(`(function(a,m,o,c,r,m){a[m]={id:"39501",hash:"d57954a332ead35c950ee7a39226c22ed791ee2033189ed77dd8f346ff1ee50a",locale:"en",inline:false,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.com/js/button.js?1616513092';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));`)
    //   /* eslint-enable */
    //   script.appendChild(innerJsCode)
    //   document.getElementById('amoBtn').appendChild(script)
    //   // Custom scroll plugins
    //   const scrollTrigger = require('./home-assets/js/ScrollTrigger.min.js')
    //   const { ScrollTrigger } = scrollTrigger.window
    //   window.ScrollTrigger = ScrollTrigger
    //   window.gsap = require('./home-assets/js/gsap.min.js')
    //   const { LocomotiveScroll } = require('./home-assets/js/locomotive-scroll.min.js')
    //   window.LocomotiveScroll = LocomotiveScroll
    //   window.plugins = require('./home-assets/js/plugins/plugins.min.js')
    //   // Active js
    //   window.active = require('./home-assets/js/active.js')
    //   window.slider = require('./home-assets/js/slider.js')
    //   // Parallax animation
    //   window.scrollParallax = require('./home-assets/js/scroll-parallax.js')
    //   window.lottieAnimations = require('./home-assets/js/lottie-animations.js')
    //   window.resizing = require('./home-assets/js/resizing.js')
    // }
  },
};
</script>

<style lang="scss">
@import 'home-assets/css/style.scss';
@import 'assets/sass/utils/variables';
@import '../node_modules/swiper/dist/css/swiper.min.css';

.rocket-area-row {
  display: flex;
  justify-content: center;
  margin: 0 -15px 188px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    margin-left: -20px;
    margin-right: -20px;
  }

}

.rocket-area-item-container {
  width: 33%;
  min-width: 33%;
  flex-grow: 1;
  padding: 12px;

  @media (max-width: 991px) {
    width: 50%;
    min-width: 50%;
    flex-grow: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 352px;
  }

}

.rocket-area-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 424px;
  padding: 58px 30px 10px;
  background: $color-white;
  border-radius: 32px;
  text-align: center;

  @media (max-width: 767px) {
    height: 360px;
  }

  &_first {
    padding-top: 74px;

    @media (max-width: 767px) {
      padding-top: 67px;
    }

  }

  &_second {
    padding-top: 49px;

    @media (max-width: 767px) {
      padding-top: 33px;
    }

  }

  &__icon {
    @media (max-width: 767px) {
      max-width: 72px;
    }
  }

  &__text {
    margin-top: 7px;
    margin-bottom: 33px;
    font-size: 24px;
    line-height: 1.33;
    font-weight: 700;
    color: #1E2125;
    letter-spacing: 1px;

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }

    span {
      display: block;
      margin-top: 7px;
      font-size: 18px;
      font-weight: 500;
      color: #969696;
      letter-spacing: 0;
    }

  }

  &__counter {
    font-size: 9px;
    margin-top: -13px;
    margin-bottom: 26px;

    @media (max-width: 767px) {
      margin-bottom: 17px;
    }

  }

  &__btn {
    display: flex!important;
    width: 100%;
    max-width: 264px;
    padding: 5px 15px!important;
    letter-spacing: 1.1px;
    align-items: center;
    justify-content: center;
    font-size: 18px!important;
    height: 56px!important;
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 16px!important;
      height: 48px!important;
      max-width: 248px;
    }

  }

}

.star-images {
  img {
    width: 32px;
  }
}

.button-group_today {
  margin-top: 56px;

  @media (max-width: 575px) {
    margin-top: 25px;
    align-items: center;
  }
}

.button-group_yellow {
  @media (min-width: 1025px) {
    position: relative;
    left: -327px;
    top: -80px;
  }

  @media (max-width: 575px) {
    align-items: center;
  }
}

.button-new-design {
  font-size: 18px !important;
  letter-spacing: 0.9px !important;
  padding: 0!important;
  width: 224px!important;
  height: 56px !important;
  display: flex!important;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    font-size: 16px !important;
    width: 328px!important;
    height: 48px !important;
  }
}

.btn-feature {
  @media  (min-width: 768px) {
    letter-spacing: 0.5px!important;
    margin-top: 50px !important;
    font-size: 18px !important;
    padding-left: 15px !important;
    padding-right: 15px!important;
    width: 224px!important;
    height: 56px !important;
    display: flex!important;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 575px) {
    padding: 0.7rem 6.7rem !important;
    letter-spacing: 0.4px!important;
  }
}

.tizer-title {
  @media  (min-width: 1025px) {
    position: relative;
    top: -60px;
  }
}

.flex-start {
  display: flex;
  justify-content: flex-start !important;
}

.feedback {

  &-title {
    @media  (min-width: 1025px) {
      margin: 37px 0 70px!important;
    }

    @media (max-width: 575px) {
      padding: 0 10px;
    }
  }

  &-text {

    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
    }
  }

}

.featured-icon_mobile {
  @media (max-width: 575px) {
    max-width: 84px;
  }
}

.section-pin-pc {
  &__text {
    margin: 0 auto 20px!important;
  }

  @media (min-width: 1024px) {
    &__title {
      margin-top: 73px!important;
      margin-bottom: 32px!important;
    }

    &__text {
      font-size: 20px!important;
      line-height: 30px!important;

      &_app {
        width: 510px;
        margin: 0 auto;
        position: relative;
        top: 34px;
      }
    }

    &__description {
      letter-spacing: 0.7px;
      font-size: 28px!important;
      top: 31px;
      position: relative;

      &_app {
        top: 3px;
        letter-spacing: 1px;
        width: 600px;
      }
    }
  }

  @media (max-width: 575px) {
    &__text {
      font-size: 16px!important;
      line-height: 24px!important;

      &_app {
        width: 300px;
        top: 19px;
        position: relative;
      }
    }

    &__description {
      font-size: 18px !important;
      letter-spacing: 0.4px;

      &_app {
        width: 230px;
        position: relative;
        line-height: 23px!important;
        top: 9px;
        margin: 0 auto;
      }
    }
  }
}

.trusted {

  &__title {
    @media  (min-width: 1025px) {
      margin-bottom: 67px !important;
    }
  }
}

.fb-name {
  font-weight: 800 !important;
}

.fb-body {
  font-size: 22px !important;
  line-height: 34px !important;

  @media (max-width: 575px) {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}

.text-dusty { color: $color-dusty-gray-second !important;}

.star_new {
  width: 31px !important;

  @media (max-width: 575px) {
    width: 21px !important;
  }

  &-title {
    font-size: 20px !important;
    letter-spacing: 0.2px;
    @media (max-width: 575px) {
      font-size: 16px !important;
    }
  }
}

.promo-text-footer {
  letter-spacing: 1.5px !important;

  @media (max-width: 575px) {
    letter-spacing: 0.6px !important;
  }
}

iframe {
  transition: opacity .2s ease-in;
}

@media (min-width: 1025px) {
  .s_desktop {
    display: none!important;
  }

  .section-slide1 {
    height: 100vh;
    max-height: 1080px;
    //background: url('./home-assets/img/feature-thumb/Slider_2.jpg') center 0 no-repeat;

  }

  .section-slide2 {
    height: 100vh;
    max-height: 1080px;
    //background: url('./home-assets/img/feature-thumb/Slider_3.jpg') center 0 no-repeat;

  }

  .section-slide3 {
    height: 100vh;
    max-height: 1080px;
    //background: url('./home-assets/img/feature-thumb/Slider_1.jpg') center 0 no-repeat;

  }

}

.footer-social {
  svg {
    width: 100%;
    fill: currentColor;
  }
}

</style>

<style lang="scss" scoped>
@import 'assets/sass/utils/variables';

.white-tab {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto!important;
  margin-right: auto!important;
  margin-top: 30px;
  padding: 57px 27px 54px 49px;

  @media (max-width: 1199px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 991px) {
    margin-left: 8px!important;
    margin-right: 8px!important;
    width: auto!important;
    padding-top: 33px!important;
    padding-bottom: 12px!important;
  }

}

.white-tab-item {
  position: relative;
  width: 33.33%;
  min-width: 33.33%;

  @media (max-width: 991px) {
    width: 50%;
    min-width: 50%;
  }

  &_website {
    padding-top: 9px;

    .white-tab-item__icon {
      margin-bottom: 21px!important;

      @media (max-width: 991px) {
        margin-bottom: 15px!important;
        max-width: 55px;
      }

    }
  }

  &_crm {
    @media (max-width: 991px) {
      padding-top: 7px;

      .white-tab-item__symbal {
        display: none;
      }
    }
  }

  &_heart {
    padding-top: 7px;
    padding-right: 6px;

    .white-tab-item__symbal {
      left: -18px;
    }

    @media (max-width: 991px) {

      .white-tab-item__icon {
        img {
          max-width: 38px !important;
        }
      }
    }
  }

  &_forms {
    padding-top: 6px;

    .white-tab-item__symbal {
      display: none;

      @media (max-width: 991px) {
        display: block;
        top: 27px;
      }

    }

    @media (max-width: 991px) {
      padding-left: 2px!important;

      .white-tab-item__icon {
        max-width: 42px !important;
      }
    }

  }

  &_bulb {

    .white-tab-item__icon {
      margin-bottom: 10px!important;
    }

    .white-tab-item__symbal {
      top: 56px;
      left: -18px;

      @media (max-width: 991px) {
        display: none;
      }

    }

  }

  &_sale-icon {
    .white-tab-item__symbal {
      top: 56px;

      @media (max-width: 991px) {
        top: 41px;
      }

    }

    .icon-box {
      padding-top: 4px!important;
    }

    @media (max-width: 991px) {
      padding-top: 10px !important;
      padding-left: 20px !important;

      .white-tab-item__icon {
        width: 92px !important;
        max-width: 92px !important;

        img {
          max-width: 100% !important;
        }

      }
    }

  }

  &__icon {
    margin-bottom: 16px!important;

    @media (max-width: 991px) {
      max-width: 38px ;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 13px!important;
    }

  }

  @media (max-width: 991px) {
    &__title {
      font-size: 16px;
      margin-bottom: 2px;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
    }

    .icon-box {
      padding-top: 0!important;
    }

  }

  &__symbal {
    position: absolute;
    top: 65px;
    left: 4px;
    font-size: 2.85em;

    @media (max-width: 1199px) {
      left: -13px;
    }

    @media (max-width: 991px) {
      top: 39px;
      left: -5px!important;
      font-size: 1.85em;
    }

  }

}
//
//.pin-wrap-trigger, .pin-wrap2-trigger {
//  top: 0!important;
//}

.rocket-area-country {
  width: 100%;
  margin-bottom: 24px;
  padding: 102px 45px 45px;
  background: #FFFFFF;
  border-radius: 32px;
  text-align: center;

  @media (max-width: 991px) {
    padding: 46px 26px 27px;
  }

  &__title {
    width: 65%;
    margin: 0 auto 35px;
    font: 48px $font-global-bold;
    line-height: 56px;
    color: #1E2125;

    @media (max-width: 1199px) {
      width: 83%;
    }

    @media (max-width: 991px) {
      margin-bottom: 11px;
      font-size: 24px;
      line-height: 32px;
      width: 100%;

      span {
        display: block;
      }

    }

  }

  &__text {
    font: 18px $font-global;
    line-height: 32px;
    color: #969696;

    @media (max-width: 991px) {
      font-size: 16px;
    }
  }

}

.rocket-area-country-list {
  display: flex;
  margin: 0 -11px 29px;
  justify-content: center;

  @media (max-width: 991px) {
    width: 177px;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9px;
  }

  &__item {
    padding: 11px;
    width: 92px;

    @media (max-width: 991px) {
      padding: 8px 7px;
      width: 59px;
    }

  }

}

.welcome-area {

  position: relative;

  @media (max-width: 767px) {
    padding-top: 80px;

    .container {
      position: relative;
    }

  }

  &__continent {
    position: absolute;
    width: calc(100% - 66px);
    max-width: 1013px;
    top: -1px;
    margin-left: 66px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;

    @media (max-width: 767px) {
      width: 405px;
      top: -70px;
      margin-left: 0;
    }

  }

}

.footer-bottom {
  text-align: center;
  padding-top: 75px;
  color: rgba(255, 255, 255, .75);

  @media (max-width: 767px) {
    padding-top: 44px;
    padding-bottom: 20px;
  }

}

.footer-verified {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
  font: 16px $font-global-medium;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 14px;
  }

  img {
    margin-right: 4px;
  }

}

.footer-card {
  display: flex;
  justify-content: center;
  margin-bottom: 31px;

  @media (max-width: 767px) {
    margin-bottom: 17px;
  }

  &__item {
    width: 72px;
    padding: 0 9px;
    opacity: .75;

    @media (max-width: 767px) {
      width: 64px;
      padding: 0 8px;
    }

  }

}

.footer-info {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #969696;

  &__organization {
    color: inherit!important;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: -3px;

    &__organization {
      display: block;
    }

  }

  &__link {
    cursor: pointer;
    color: #C3C3C4!important;
    transition: color .2s ease-in;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $color-white!important;
    }

  }

}

.footer-social {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }

  &__item {
    width: 38px;
    color: #C3C3C4;
    transition: color .2s ease-in;

    @media (max-width: 767px) {
      width: 32px;
    }

    &:hover {
      color: $color-white;
    }

    & + & {
      margin-left: 20px;

      @media (max-width: 767px) {
        margin-left: 16px;
      }

    }
  }

}

.navbar-inner {

  &__logo {
    display: none;
  }

}

@media (max-width: 991px) {
  .navbar-inner {
    display: flex;
    flex-direction: column;
    padding: 53px 40px 68px;
    width: 19rem!important;

    .navbar-toggler {
      left: auto!important;
      right: 30px;
      top: 20px !important;
    }

    &__logo {
      display: block;
      width: 180px;
      margin-bottom: 23px;
    }

    nav {
      margin-top: auto;

      .btn {
        width: 100%;
        margin: 0;

        & + .btn {
          margin-top: 15px;
        }

      }

    }

  }
}

.navbar-inner-navigation {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }

  &__item {
    padding: 5px 0;
    font: 16xp / 48px $font-global;
    transition: color .2s ease-in;

    &:hover {
      color: $color-turquoise;
    }

    &_active {
      pointer-events: none;
      color: $color-turquoise;
    }

  }

}

.pin-wrap2 {
  .section-slide1, .section-slide2, .section-slide3 {
    position: relative;
    overflow: hidden;
  }

  @media (min-width: 1025px) {

    .section-row {
      position: relative;
      z-index: 2;
    }

  }

  .s_desktop {
    display: block !important;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    width: auto!important;
    z-index: 1;
  }

  .s_mobile {
    //position: absolute;
    //top: 0;
    //left: 50%;
    //transform: translateX(-50%);
    //max-width: none;
    //width: auto!important;
    //z-index: 1;
  }
}

.carousel-item {
  float: none!important;
  display: block!important;
  margin-right: 0!important;
  backface-visibility: visible!important;
}

.impact-amo-form {
  position: relative;
  max-width: 625px;
  width: 100%;
  margin: 24px auto -20px;

  &__input {
    width: 100%;
    height: 48px;
    padding: 0 200px 0 24px;
    background: $color-white;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 24px;
    font: 16px $font-global;
    color: rgba(144, 144, 144, 0.75);

    @media (max-width: 767px) {
      padding-right: 24px;
    }

    &_error {
      border-color: $color-punch !important;
      background-color: $color-white !important;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 0 28px;
    border-radius: 24px;
    font: 16px $font-global-bold;
    color: $color-white;
    //background: #FC5EA9;
    cursor: pointer;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    z-index: 1;
    outline: none!important;
    transition: color .3s ease-in;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #FC5EA9;
      border-radius: 24px;
      z-index: -2;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #ffffff;
      border: 2px solid #FC5EA9;
      transition: all 0.3s;
      border-radius: 24px;
      z-index: -1;
    }

    &:hover {
      color: #FC5EA9;

      &:before {
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      position: relative;
      width: 100%;
      margin-top: 16px;
    }

  }

  &__thank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border-radius: 24px;
    background: $color-white;
    font: 18px $font-global-medium;
    color: #909090;

    @media (max-width: 767px) {
      margin-bottom: 64px;
    }

    a {
      margin-left: 5px;
      color: $color-bright-turquoise;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

    }
  }

}

.help {
  position: absolute;
  top: -26px;
  left: 20px;
  font-size: 12px;
  color: $color-punch;
  box-shadow: none;
  padding: 0;
  background: transparent;
  transform: translateY(0);
}

</style>

<!-- Created by zas on 29.03.2021  -->
